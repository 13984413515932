export const StoryPage = () => {
    return (
        <div className="storyPage" >
           <div className="stories">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquid corporis esse assumenda animi soluta autem! Quos architecto iure asperiores maxime dolorem accusamus nulla repellat! Architecto ab numquam tenetur sint!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquid corporis esse assumenda animi soluta autem! Quos architecto iure asperiores maxime dolorem accusamus nulla repellat! Architecto ab numquam tenetur sint!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquid corporis esse assumenda animi soluta autem! Quos architecto iure asperiores maxime dolorem accusamus nulla repellat! Architecto ab numquam tenetur sint!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquid corporis esse assumenda animi soluta autem! Quos architecto iure asperiores maxime dolorem accusamus nulla repellat! Architecto ab numquam tenetur sint!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, aliquid corporis esse assumenda animi soluta autem! Quos architecto iure asperiores maxime dolorem accusamus nulla repellat! Architecto ab numquam tenetur sint!</p>
           </div>
           <div className="writeComment" > 
            <textarea className="textArea" cols={50} rows={10} placeholder="Write comment for story" />
            <div className="sendBtn" >
                Send comment
            </div>
           </div>
        </div>
    )
}