import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Tooltip } from '@mui/joy';
import { useEffect, useState } from 'react';

export interface StoryProps {
  story: any;
}

export const Story = ({story}: StoryProps) => {

  const [isLiked, setIsLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(16)

  useEffect(() => {
   setLikeCount(isLiked ? likeCount + 1 : likeCount - 1);
  }, [isLiked])

  return (
    <Card
      variant="outlined"
      sx={{
        width: 450,
        height: 250,
        // to make the card resizable
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Avatar src="/static/images/avatar/1.jpg" size="lg" />
        <div className="tags">
          {
            story.tags.map((tag: any) => {
              return <p>{tag},</p>
            })
          }
        </div>
        <AvatarGroup size="sm" sx={{ '--Avatar-size': '28px' }}>
          <Avatar src="/static/images/avatar/2.jpg" />
          <Avatar src="/static/images/avatar/3.jpg" />
          <Avatar src="/static/images/avatar/4.jpg" />
          <Tooltip title="John Doe, Teszt ELek, Mek Elek">
            <Avatar>+3</Avatar>
          </Tooltip>
        </AvatarGroup>
      </Box>
      <CardContent>
        <Typography level="h5" fontWeight="lg">
          {story.title}
        </Typography>
        <Typography level="body2">
         {story.content}
        </Typography>
      </CardContent>
      <CardActions buttonFlex="0 1 120px">
        <IconButton variant="plain" color="neutral" sx={{ mr: 'auto' }} onClick={()=>setIsLiked(!isLiked)} >
         { isLiked ? <FavoriteIcon color='error' /> : <FavoriteBorder />}
         <p>+{likeCount}</p>
        </IconButton>
        <Button variant="solid" color="primary">
          View
        </Button>
      </CardActions>
    </Card>
  );
}