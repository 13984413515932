import { BrowserRouter as Router, Route, Routes,redirect } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { StoryPage } from './pages/Story/StoryPage';
import { StoryList } from './pages/StoryList/StoryList';
import { routes } from './constants/routes';
import { SignUp } from './pages/SignUp/SignUp';
import { CreateStory } from './pages/NewStory/CreateStory';

export const AppRouting = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" action={()=>redirect(routes.storyList)}  />
                <Route path={routes.login} element={<Login />} />
                <Route path={routes.signUp} element={<SignUp />} />
                <Route index path={routes.storyList} element={<StoryList />} />
                <Route path={routes.story} element={<StoryPage />} />
                <Route path={routes.createStory} element={<CreateStory />} />
                <Route path="*" element={<div>404</div>} />
            </Routes>
        </Router>
    )
}