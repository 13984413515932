import { useNavigate } from "react-router-dom"
import { routes } from "../../constants/routes";
import { useState } from "react";
import { UserService } from "../../service/UserService";
import { useSetRecoilState } from "recoil";
import { userAtom } from "../../context/user.atom";

export const Login = () => {
  const navigate = useNavigate();
  const setUserEmail = useSetRecoilState(userAtom);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const result = await UserService.login(email, password)
      if (result) {
        setUserEmail(result.email);
        navigate(routes.storyList)
      }
    } catch (error) {
      console.log(error);
    }
  }

    return (
      <div className="login">
        <div className="loginMask"></div>
        <div className="login-content">
          <div className="loginFormPanel">
            <p className="loginTitle" >CollaboWrite</p>
            <div className="form">
              <input type="text" placeholder="email" onChange={(e)=>setEmail(e.target.value)} />
              <input type="password" placeholder="password" onChange={(e)=>setPassword(e.target.value)} />
              <div className="loginBtn" onClick={() => handleLogin()} >
                Login
              </div>
              <div className="signUpBtn" onClick={() => navigate(routes.signUp)} >
                Sign up
              </div>
            </div>
          </div>
          <div className="randomSzoveg">
            <p className="qoutes" >"Without culture, and the relative freedom it implies, society, even when perfect, is but a jungle. This is why any authentic creation is a gift to the future."</p>
            <p>Albert Camus</p>
          </div>
        </div>
      </div>
    )
  }