export const setToken = (token: string) => {
  const expires = (new Date(Date.now()+ 86400*1000)).toUTCString();
  document.cookie = `watiToken=${token}; expires=${expires};path=/;`
}

export const getCookie = (token: string) => {
  let name = token + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}
export const getCookieByName = (name:string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  //@ts-ignore
  if (parts.length === 2) return parts.pop().split(';').shift();
}