import { useNavigate } from "react-router-dom";
import { routes } from "../../../constants/routes";

export const Frame = (props: any) => {
  const navigate = useNavigate();

  return (
    <div className="frame">
      <div className="header-decor">
        <div className="nav">
          <p className="nav-link"  onClick={()=>navigate(routes.storyList)} >Stories</p>
          <p className="nav-link" onClick={()=>navigate(routes.createStory)} >New Story</p>
          <p className="nav-link" >Favourites</p>
          <p className="nav-link" >Settings</p>
        </div>
      </div>
      <div className="content">
        {props.children}
      </div>
    </div>
  )
}