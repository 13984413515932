import './App.scss';
import { RecoilRoot, useRecoilState } from 'recoil';
import { AppRouting } from './AppRouting';
import { CssVarsProvider } from '@mui/joy';
import { theme } from './style/style';
import { useEffect, useState } from 'react';
import { useResolvedPath } from 'react-router-dom';
import { UserService } from './service/UserService';
import { tokenAtom } from './context/token.atom';
function App() {
  // const [token, setToken] = useRecoilState(tokenAtom);
  const [isLogined, setIsLogined] = useState<boolean>(false);
  // const { pathname } = useResolvedPath(window.location.pathname);
  // const isValidToken = async () => {
  //   try {
  //     const response = await UserService.isMe();
  //     if (response.data) {
  //       setIsLogined(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // useEffect(() => {
  //   const path = window.location.pathname;
  //   if (path !== '/login') {
  //     isValidToken()
  //   }
  // }, [])

  // useEffect(() => {
  //   if (isLogined) {
  //     onLoadUsers();
  //   }
  // }, [isLogined])

  // useEffect(() => {
  //   if (token === 'valid') {
  //     setIsLogined(true);
  //   } else if (token === 'noValid') {
  //     setIsLogined(false)
  //   }
  // }, [token])
  return (
    <div className="App">

      <CssVarsProvider theme={theme} >
        <RecoilRoot>
          <AppRouting />
        </RecoilRoot>

      </CssVarsProvider>
    </div>
  );
}

export default App;
