import { routes } from '../constants/routes';
import { getCookieByName } from './../utils/cookieUtils';
import axios from "axios"
export const BASE_PATH = 'http://193.39.13.188:8888/api'
export const axiosInstance = axios.create()
axios.defaults.withCredentials = true;
axiosInstance.interceptors.request.use((config) => {
  const token = getCookieByName('collaboToken');
  config.headers.Authorization = token ?? '';
  return config;
})

axiosInstance.interceptors.response.use((response) => {
    return response;
  },(err) => {
    const status = err.response.status;
    if(status === 401){
     const pathName =  window.location.pathname
     if(pathName !== routes.login){      
      window.location.pathname = routes.login;
     }
    }
    Promise.reject(err);
  }
)
