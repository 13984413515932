import { BASE_PATH, axiosInstance } from ".";

export const UserService = {
    async login (email: string, password: string) {
        const response = await axiosInstance.post(`${BASE_PATH}/auth/login`, { email, password });
        return response.data;
    },
    async signUp (signUpDTO: any) {
        const response = await axiosInstance.post(`${BASE_PATH}/auth/sign-up`, signUpDTO);
        return response.data;
    },
    async isMe(){
        const response = await axiosInstance.get(`${BASE_PATH}/auth/isme`);
        return response.data;
    }
}