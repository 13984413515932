import { extendTheme } from "@mui/joy";
import { Color } from "./colors";

declare module '@mui/joy/Button' {
  interface ButtonPropsColorOverrides {
    secondary: true;
    tertiary: true;
  }
}

export const theme = extendTheme({
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'primary' && {
            color: "white",
            backgroundColor: Color.primary,
            transition: "background-color 0.3s ease",
            '&:hover': {
              backgroundColor: Color.primaryDark,
            },
          }),
        }),
      },
    },
  },
});