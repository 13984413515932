import { Button } from "@mui/joy"
import { Frame } from "../../components/custom/Frame/Frame"
import { Story } from "../../components/custom/Story/Story"
import { SearchBar } from "../../components/custom/SearchBar/SearchBar"
import { useEffect, useState } from "react"
import { StoryDTO } from "../../models/Story.model"
import { StoryService } from "../../service/StoryService"

export const StoryList = () => {
  const [stories, setStories] = useState<StoryDTO[]>([]);

  const getStories = async () => {
    try {
      const result = await StoryService.getStoryList();
      if (result) {
        setStories(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getStories();
  }, [])

  return (
    <Frame>
      <div className="storyList">
        <div className="storyList-content">
          {
            stories.map((story) => {
              return <Story story={story} />
            })
          }
        </div>
        <div className="search">
          <SearchBar />
s        </div>
      </div>

    </Frame>
  )
}