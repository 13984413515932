import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../service/UserService";
import { routes } from "../../constants/routes";
import { Alert, Checkbox } from "@mui/joy";

export const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [gender, setGender] = useState('');
  const [alertType, setAlertType] = useState<string | undefined>();
  const handleSignUp = async () => {
    try {
      const result = await UserService.signUp({
        name, email, password, sex: gender, username: userName, birthDay: new Date(1992, 1, 1)
      })
      if (result) {
        setAlertType('success')
        setTimeout(() => {
          navigate(routes.login)
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setAlertType('danger')
    }
  }

  useEffect(() => { 
   console.log('alertType', alertType);
  }, [alertType])


  return (
    <div className="login">
      <div className="loginMask"></div>
      <div className="login-content">
        <div className="loginFormPanel">
          <p className="loginTitle" >CollaboWrite</p>
          <div className="form">
            <input type="text" placeholder="email" onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} />
            <input type="text" placeholder="User name" onChange={(e) => setUserName(e.target.value)} />
            <input type="text" placeholder="Gender" onChange={(e) => setGender(e.target.value)} />
            <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
            <div className="requiredCheckox" >
              <Checkbox />
              <p>Creating an account means you’re okay with our <a href="">Terms of Service</a>, <a href="">Privacy Policy.</a></p>
            </div>
            <div className="signUp-btn" onClick={() => handleSignUp()} >
              Sign up
            </div>
          </div>
        </div>
        <div className="randomSzoveg">
          <p className="qoutes" >"Without culture, and the relative freedom it implies, society, even when perfect, is but a jungle. This is why any authentic creation is a gift to the future."</p>
          <p>Albert Camus</p>
        </div>
      </div>
      {alertType && <Alert color={alertType as any} >{alertType == 'danger' ? 'Sajnos hiba történt' : 'Sikeres regisztráció'}</Alert>}
    </div>
  )
}