import { Alert, Autocomplete, AutocompleteOption, Input, ListItemDecorator, Textarea } from "@mui/joy"
import { Frame } from "../../components/custom/Frame/Frame"
import { InnerInput } from "../../utils/InnerInput"
import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { StoryService } from "../../service/StoryService";
import { useRecoilState } from "recoil";
import { userAtom } from "../../context/user.atom";

export const CreateStory = () => {
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [story, setStory] = useState('');
  const [alertType, setAlertType] = useState<string | undefined>();
  const [userEmail, setUserEmail] = useRecoilState(userAtom);

  const resetForm = () => {
    setTitle('');
    setTags([]);
    setStory('');
  }

  const onCreateStory = async () => {
    try {
      const result = await StoryService.createStory({
        title, tags, content: story, createdBy: userEmail
      })
      if (result) {
        setAlertType('success')
        resetForm();
      }
    } catch (error) {
      console.log(error);
      setAlertType('danger')
    }
  }

  return (
    <Frame>
      {alertType && <Alert color={alertType as any} >{alertType == 'danger' ? 'Sajnos hiba történt' : 'Succes created story'}</Alert>}
      <div className="createStory">
        <p>Create Story</p>
        <div className="story-form">
          <p>Add title</p>
          <Input placeholder="Title" onChange={(e) => setTitle(e.currentTarget.value)} />
          <p>Add tags</p>
          <Autocomplete
            value={tags}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setTags([...tags, newValue]);
              } else {
                setTags(newValue);
              }
            }}
            multiple={true}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            options={[]}

            renderOption={(props, option) => (
              <AutocompleteOption {...props}>
                {option?.startsWith('Add "') && (
                  <ListItemDecorator>
                    <Add />
                  </ListItemDecorator>
                )}
                {option}
              </AutocompleteOption>
            )}
            sx={{ width: 300 }}
          />
          <p>Create story episode</p>
          <Textarea
            placeholder="Type in here…"
            defaultValue="Create awesome story."
            minRows={8}
            maxRows={8}
            onChange={(e) => setStory(e.currentTarget.value)}
          />
          <div className="create-btn" onClick={onCreateStory} >
            Create
          </div>
        </div>

      </div>
    </Frame>
  )
}