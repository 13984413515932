import { BASE_PATH, axiosInstance } from "."

export const StoryService = { 
    async getStoryList () {
      return await axiosInstance.get(`${BASE_PATH}/story/list`)
    },
    async getStory (id: string) {
      return await axiosInstance.get(`${BASE_PATH}/story/${id}`)
    },
    async createStory (storyDTO: any) {
      return await axiosInstance.post(`${BASE_PATH}/story/create`, storyDTO)
    }
  }